import React, {useEffect, useState} from "react";
import Auth from "@aws-amplify/auth";
import {input, Storage} from 'aws-amplify';
import '../Styles/vales.css'
import '../Styles/main.css'
import '../Styles/modal.css'
import moldex from '../Media/SVG/moldex-logo.svg'
import axios from "axios";
import Modal from 'react-modal'
import cargando from '../img/loading.gif'
import error from '../img/error.png'
import userLogo from '../img/user.svg'
function Login ({ onLogIn }){
    const [vale, setVale] = useState('')
    const [valesPreview, setValesPreview] = useState([])
    const [uploableFiles, setFiles] = useState(null)
    const [key, setNewKey] = useState("")
    const [inputButtons, setInputButtons] = useState([{id:0}])
    const [idbutton, setIdButton] = useState(0)
    const [message, setMessage] = useState(['default', 'messageDefault'])
    const [valeFin, setValeFin] = useState(false)
    const [user, setUser] = useState('default')
    const [tokenValidated, setTokenValidated] = useState(true)
    const [imageModal, setImageModal] = useState(cargando)
    const params = new URLSearchParams(document.location.search)
    const [textModal, setTextModal] = useState('Validando Token')
    useEffect(()=>{
        setVale(params.get('ot'))
        Auth.signIn('gerardo.ramirez04@grupobimbo.com', 'V3d3t3c!23').then((response)=>{
            if(response.challengeName === 'NEW_PASSWORD_REQUIRED'){
            }else{
                Storage.list('Vales/').then(response=>{
                    console.log(response)
                })
            }
        })
        searchSession()
    },[])
    useEffect(()=>{
        console.log(vale)
    },[vale])

    const searchSession = async() =>{
    // const recipeUrl = 'https://produccion.moldexmexico.com/login';
    // const postBody = {
    //     user: "123456789",
    //     pass: '123'
    // };
    // const requestMetadata = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'mode': 'no-cors'
    //     },
    //     body: JSON.stringify(postBody)
    // };
    // console.log('OT: ',params.get('ot'))
    // console.log('OT: ',params.get('token'))
    // console.log('OT: ',params.get('user'))
    axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'validateToken', token: params.get('token'), user: params.get('user')}).then(response=>{
        if(response.data === false){
            setImageModal(error)
            setTextModal('Error de acceso')
        }else{
            console.log('Token validado correctamente')
            setTokenValidated(false)
            setUser(response.data.user)
        }
    })
    // fetch(recipeUrl, requestMetadata)
    //     .then((res) => {
    //         console.log("Esta es la respuesta")
    //         res.json()
    //         .then(response=>{
    //             console.log(response)
    //             console.log(response.user.user.nombre_ap)
    //             setUser(response.user.user.nombre_ap)
    //         })
    //     })
    }
    const showFile = async() =>{
        var arrayImage=[];
        inputButtons.forEach(element => {
            const inputFile = document.querySelector("#input-id-" + element.id)
            if (inputFile.files.length > 0){
                for(let i = 0; i < inputFile.files.length; i++){
                    arrayImage.push({path : URL.createObjectURL(inputFile.files[i]), name: inputFile.files[i].name})
                }
            }
        });
        setValesPreview(arrayImage)
    }

    const ChargeFilesS3 = async()=>{
        console.log('Charging files to S3')
        if(valesPreview.length > 0){
            var flag = 0
            var uploadedFiles = [];
            inputButtons.forEach(element => {
                const inputFile = document.querySelector("#input-id-" + element.id)
                if (inputFile.files.length === 0)
                    flag = 1
            });
            if(flag === 1){
                setMessage(['Advertencia', 'Carga una imagen en todas las casillas o regresa a Inicio!'])
                setModalIsOpen(true)
            }else{
                console.log('Listo para cargar vales')
                inputButtons.forEach(element => {
                    const inputFile = document.querySelector("#input-id-" + element.id)
                    var filesToUpload = inputFile.files.length
                    for (let i = 0; i < filesToUpload; i++) {
                        console.log(inputFile.files[i])
                        Storage.put('Data/' + vale + '-' + inputFile.files[i].name, inputFile.files[i],{
                            level: 'public',
                        }).then((response)=>{
        
                            console.log(response.key);
                            setNewKey(response.key)
                            axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'insertVale', ot: vale, imagen: vale + '-' +  inputFile.files[i].name }).then(response=>{
                                console.log('Esta es la respuesta')
                                console.log(response)
                                uploadedFiles.push(response)
                                if(uploadedFiles.length === valesPreview.length){
                                    // alert('Vales cargados correctamente')
                                    // window.location.href = 'https://produccion.moldexmexico.com/seccionN';
                                    setValeFin(true)
                                }
                            })
                        }).catch(error=>{
                            alert(error)
                        })
                    }
                });        
            }
        }else{
            // alert('Carga archivos porfis')
            setMessage(['Advertencia', 'Carga los vales!'])
            setModalIsOpen(true)
        }
    }
    useEffect(()=>{
        if(key != ""){
            console.log(key)
            Storage.get(key).then(response=>{
                console.log(response)
            })
        }
    },[key])

    const AgregarVale = () =>{
        var valestt = inputButtons;
        console.log(valestt)
        valestt.push({id: idbutton + 1})
        setInputButtons(valestt)
        setIdButton(idbutton + 1)
    }
    const [modalIsOpen, setModalIsOpen] = useState(false)
    if(params.get('ot') === null || params.get('user') === null || params.get('token') === null){
        return(
            <div>
                <h1>Acceso denegado</h1>
            </div>
        )
    }else 
    return (
    <div className="page-container">
        <Modal className='token-modal' style={{ modal: {}, overlay: { background: "#0000" } }} isOpen={tokenValidated} >
            <h1>{textModal}</h1>
            <img src={imageModal}></img>
            <button type='button' onClick={()=>window.location.href = 'https://produccion.moldexmexico.com'}>Inicio</button>
        </Modal>
        <Modal className="modal-menu" style={{ modal: {}, overlay: { background: "#0000" } }} isOpen={valeFin} >
            <h1>Listo</h1>
            <p>Vales cargados correctamente</p>
            <button onClick={()=>window.location.href = 'https://produccion.moldexmexico.com/seccionN'} type='button'>Enterado</button>
        </Modal>
        <Modal className="modal-menu" style={{ modal: {}, overlay: { background: "#0000" } }} isOpen={modalIsOpen} onRequestClose= {() => setModalIsOpen(false)}>
            <h1>{message[0]}</h1>
            <p>{message[1]}</p>
            <button onClick={()=>setModalIsOpen(false)} type='button'>Enterado</button>
        </Modal>
        <div className='leftbar'>
            <img onClick={()=> window.location.href="https://produccion.moldexmexico.com/home"} src={moldex} className='logo-left'/>
        </div>
        <div className='right-content'>
            <div className="topbar">
                <div className='topbar-items'>
                    <div className='h2-left item-topbar'>QR</div>
                    <div className='item-topbar'>Plataform</div>
                </div>
                <div className='topbar-items items-right'>
                    <div className='item-topbar'>{user}</div>
                    <div className='item-topbar'>
                        <img className='item-icon' src={userLogo}/>
                    </div>
                </div>
                
            </div>
            <div className='main-content'>
                <div className='vales-view'>
                    <div className='view-title'>
                        <div className='page-title'>
                            Carga de vales
                        </div>
                        <div className='page-title'>
                            <button className='home-button' type='button' onClick={()=>window.location.href ='https://produccion.moldexmexico.com/home'}>Inicio</button>
                        </div>
                    </div>
                    <div className='page-real-content'>
                    {/* Aqui van las otras vistas */}
                        <div className='division-vales'>
                            <div className='vales-left'>
                                <div className='little-title'>Seleccion de Archivos</div>
                                {
                                    inputButtons.map((info, index)=>{
                                        return(
                                            <div className='file-loader'>
                                                <input id={'input-id-' + index} className='file-selector' onChange={(e)=> showFile(e.target.files)} type='file' multiple/>
                                            </div>
                                        )
                                    })
                                }
                                <div className='button-container'>
                                    <button type='button' className='add-vale' onClick={()=>AgregarVale()}>Agregar Foto</button>
                                    <button type='button' className='upload-button' onClick={()=> ChargeFilesS3()}>Cargar Vales</button>
                                </div>
                            </div>
                            <div className='vales-right'>
                                <div className='data-container'>
                                    {valesPreview.map((data, index)=>{
                                        return(
                                                <img key={'image-key-' + index} className='data-target' src={data.path}/>
                                        )
                                    }  )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
        );
}

export default Login;
