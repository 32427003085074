import React, {useEffect, useState} from "react";
import Auth from "@aws-amplify/auth";
import {Storage} from 'aws-amplify';
import '../Styles/vales.css'
import '../Styles/main.css'
import moldex from '../Media/SVG/moldex.svg'
import entrega from '../Media/SVG/entrega.svg'
import userLogo from '../img/user.svg'
import axios from "axios";
function Show ({ onLogIn }){
    const [vale, setVale] = useState('')
    const [valesPreview, setValesPreview] = useState([])
    const params = new URLSearchParams(document.location.search)
    const [user, setUser] = useState('default')
    useEffect(()=>{
        setVale(params.get('ot'))
        console.log(params.get('user'))
        Auth.signIn('gerardo.ramirez04@grupobimbo.com', 'V3d3t3c!23').then((response)=>{
            if(response.challengeName === 'NEW_PASSWORD_REQUIRED'){
                console.log('Cambiando la contrase;a')
            }
        })
        axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'getUser', user:params.get('user')})
            .then(response=>{
                setUser(response.data.user)
            })
    },[])
    useEffect(()=>{
        if(vale != ''){
            console.log(vale);
            console.log('Getting all the vales')
            axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'getVales', ot: vale}).then(response=>{
            console.log('Esta es la respuesta')
            console.log(response)
            if(response.data){
                console.log(response)
                var images = response.data;
                var links = [];
                images.forEach(element => {
                    Storage.get('Data/' + element.image).then(respuesta=>{
                        console.log(respuesta)
                        links.push({link: respuesta})
                        if(links.length === images.length){
                            setValesPreview(links)
                        }
                    })
                });
            }else{
                alert('Ocurrio un error, no se encontro la orden de trabajo')
            }
        })
        }
    },[vale])

  return (
    <div className="page-container">
        <div className='leftbar'>
            <img onClick={()=> window.location.href="https://produccion.moldexmexico.com/home"} src={moldex} className='logo-left'/>
            {/* <div className='item-left'>
                <img src={entrega} className='icons-left'/>
                <div className='item-general-left'>Entregas</div>
            </div> */}
        </div>
        <div className='right-content'>
            <div className="topbar">
                <div className='topbar-items'>
                    <div className='h2-left item-topbar'>QR</div>
                    <div className='item-topbar'>Plataform</div>
                </div>
                <div className='topbar-items items-right'>
                    <div className='item-topbar'>{user}</div>
                    <div className='item-topbar'>
                        <img className='item-icon' src={userLogo}/>

                    </div>
                </div>
                
            </div>
            <div className='main-content'>
                <div className='vales-view'>
                    <div className='view-title'>
                        <div className='page-title'>
                            VALES OT:{vale}
                        </div>
                        <div className='page-title'>
                            <button className='home-button' type='button' onClick={()=>window.location.href ='https://produccion.moldexmexico.com/home'}>Inicio</button>
                        </div>
                    </div>
                    <div className='page-real-content'>
                        <div className='division-vales-view'>
                            {valesPreview.map((data, index)=>{
                                return(
                                    <img key={'image-key-' + index} className='data-target-view' src={data.link}/>
                                )
                            }  )}
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
  );
}

export default Show;
