import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation
} from "react-router-dom";

import Amplify, {Auth} from 'aws-amplify';
import awsconfig from '../aws-exports';
import { createBrowserHistory } from "history";
import { AnimatePresence, motion } from "framer-motion";
import Vales from '../Pages/vales'
import Home from '../Pages/home'
import Calidad from '../Pages/calidad'
import PTv from '../Pages/ptview'
import ShowVales from '../Pages/showvales'
Amplify.configure(awsconfig);

function Routes() {
  useEffect(()=>{
    console.log('AQUI estoy')
  },[])
  return (
    <Router>
      <AnimatePresence exitBeforeEnter>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/vales" component={Vales}/>
            <Route exact path='/calidad' component={Calidad}/>
            <Route exact path='/pt' component={PTv}/>
            <Route exact path='/showvales' component={ShowVales}/>
          </Switch>
      </AnimatePresence>
    </Router>
  );
}
export default Routes;
