import React, {useEffect, useState} from "react";
import Auth from "@aws-amplify/auth";
import {Storage} from 'aws-amplify';
import '../Styles/vales.css'
import '../Styles/main.css'
import '../Styles/calidad.css'
import moldex from '../Media/SVG/moldex-logo.svg'
import entrega from '../Media/SVG/entrega.svg'
import MaterialTable from "material-table";
import { Modal,TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";
import { Height } from "@material-ui/icons";
import ModalReact from 'react-modal';
const colums =[
    {
        title : 'Orden de Trabajo',
        sortable: true,
        field:'ot'
    },
    {
        title : 'Descripcion',
        sortable: true,
        field:'info'
    },
    {
        title : 'Fecha de Entrega',
        sortable: true,
        field:'date'
    },
    {
        title : 'Entrega',
        sortable: true,
        field:'entrega'
    },
    {
        title : 'Solicitante',
        sortable: true,
        field:'solicitante'
    },
    {
        title : 'Orden de Traslado',
        sortable: true,
        field:'otraslado'
    },
    {
        title : 'Cantidad',
        sortable: true,
        field:'cantidad'
    },
    {
        title : 'status',
        sortable: true,
        field:'status'
    },
]

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        width: '400px',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    iconos:{
        cursor: 'pointer'
    }, 
    inputMaterial:{
        width: '100%'
    },
    image:{
        width: '400px',
        height: '400px'
    }
}));

function Calidad ({ onLogIn }){
    const styles= useStyles();
    const [data, setData] = useState([])
    const [modalTakePicture, setModalTakePicture] = useState(false)
    const [imageModal, setImageModal] = useState('')
    const [actualOT, setActualOT] = useState(null)
    const [imageReady, setImageReady] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [comment, setComment] = useState('')
    const [warning, setWarning] = useState('')
    const [message, setMessage] = useState('default')
    const [messageContent, setMessageContent] = useState('default')
    const [modalMessage, setModalMessage] = useState(false)
    const [modalCloseOT, setModalCloseOT] = useState(false)
    const [actualCancelOT, setActualCancelOT] = useState(null)
    const [dataHistory,setDataHistory] = useState([])
    const [modalHistoryIsOpen, setModalHistoryOpen] = useState(false)
    
    useEffect(()=>{
        Auth.signIn('gerardo.ramirez04@grupobimbo.com', 'V3d3t3c!23').then((response)=>{
            if(response.challengeName === 'NEW_PASSWORD_REQUIRED'){
                console.log('Cambiando la contrasena')
            }
        })
    },[])
    useEffect(()=>{
        console.log('Getting data from database')
        axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'getOrdenesCalidad'}).then(response=>{
            console.log('Esta es la respuesta')
            console.log(response)
            setData(response.data)
        })
    },[])

    const takePicture =async(ot, event)=>{
        if(actualOT === null){
            console.log('Taking picture for ot Null : ', ot)
            setActualOT(ot)
            setModalTakePicture(true)
        }else{
            if(ot != actualOT){
                console.log('Reseteando componentes esenciales')
                setActualOT(ot)
                setImageModal('')
                setImageReady(false)
                setSelectedFile(null)
                console.log('Taking pisture for ot: ', ot)
                setModalTakePicture(true)
            }else{
                console.log('Taking pisture for ot: ', ot)
                setModalTakePicture(true)
            }
        }
    }
    const validateCorrect = async(ot, event)=>{
        console.log('Actual OT', actualOT)
        if(actualOT != null){
            if(ot === actualOT){
                if(imageReady){
                    console.log('Validating ot: ', ot)
                    console.log(selectedFile)
                    var path = 'OT' +  ot.toString() + '-' + selectedFile.lastModified.toString();
                    var format = selectedFile.name.split('.');
                    if(format.length === 2){
                        path = path + '.' + format[1]
                        console.log(path)
                        Storage.put('OrdenesPT/' + path, selectedFile).then(response=>{
                            console.log(response)
                            axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'aproveCalidad', id: actualOT, ruta: path}).then(response=>{
                                console.log('Esta es la respuesta')
                                console.log(response)
                                window.location.reload();
                            })                            
                        })
                    }else{
                        setMessage('Error');
                        setMessageContent('Error de archivo')
                        closeMessage()
                    }
                }else{
                    setMessage('Error');
                    setMessageContent('Selecciona un archivo primero')
                    closeMessage()
                }
            }else{
                setMessage('Error');
                setMessageContent('Selecciona la misma OT')
                closeMessage()
            }
        }else{
            setMessage('Error');
            setMessageContent('Carga al menos una imagen')
            closeMessage()
        }
    }

    
    const closePictureModal=()=>{
        setActualOT(null)
        setImageModal('')
        setImageReady(false)
        setSelectedFile(null)
        setModalTakePicture(!modalTakePicture);
    }
    const AcceptclosePictureModal = ()=>{
        setModalTakePicture(!modalTakePicture);
    }

    const changeDefaultPicture = (e) =>{
        setSelectedFile(e[0])
        setImageModal(URL.createObjectURL(e[0]))
    }
    const AcceptSelectedData=()=>{
        console.log('Actual OT Accept:', actualOT)
        if(actualOT != null){
            if(selectedFile != null){
                console.log('Archivo seleccionado con exito')
                setImageReady(true)
                AcceptclosePictureModal()
            }else{alert('Selecciona un archivo')}
        }else{ console.log('Error')}
    }
    const bodyTakePicture=(
        <div className={styles.modal}>
            <h2>Orden de Trabajo: {actualOT}</h2>
            <h3>Selecciona tu imagen</h3>
            <img className={styles.image} src={imageModal}/>
            <input type='file' onChange={e => changeDefaultPicture(e.target.files)}/>
            <div align="right">
                <Button color="secondary" onClick={()=>closePictureModal()} >Cancelar</Button>
                <Button color='primary' onClick={()=>AcceptSelectedData()} >Aceptar</Button>
            </div>
        </div>
    )
    
    const validateCancel =async(ot, event)=>{
        setActualCancelOT(ot)
        console.log('Canceling ot: ', ot)
        setModalCloseOT(true)
    }
    const AcceptCloseCancelOT = ()=>{
        console.log('Really canceling OT', actualCancelOT)
        console.log('Comentario: ' , comment)
        console.log('Length: ', comment.length)
        if(comment.length < 20){
            setWarning('Ingresa un comentario aceptable')
        }else{
            setWarning('')
            axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'declineCalidad', id: actualCancelOT, comment: comment}).then(response=>{
                console.log(response)
                if(response.data === true){
                    setMessage('Mensaje');
                    setMessageContent('Se elimino la OT con exito')
                    closeMessage()
                }else{
                    setMessage('Mensaje');
                    setMessageContent('Ocurrio un error')
                    closeMessage()
                }
                window.location.reload();
            })
            setModalCloseOT(!modalCloseOT);
        }
    }
    const closeCancelOT=()=>{
        setActualCancelOT(null)
        setModalCloseOT(!modalCloseOT);
    }
    const bodyCancelOT=(
        <div className={styles.modal}>
            <h2>Orden de Trabajo: {actualCancelOT}</h2>
            <h3>Motivo</h3>
            <input onChange={(e)=>setComment(e.target.value)} style={{width: '100%'}} ></input>
            <label style={{color: 'red'}}>{warning}</label>
            <h3>¿Estas seguro de cancelar la Orden de Trabajo?</h3>
            <div align="right">
                <Button color="secondary" onClick={()=>closeCancelOT()} >Cancelar</Button>
                <Button color='primary' onClick={()=>AcceptCloseCancelOT()} >Aceptar</Button>
            </div>
        </div>
    )
    const closeMessage=()=>{
        setModalMessage(!modalMessage)
    }
    const bodyMessage=(
        <div className={styles.modal}>
            <h2>{message}</h2>
            <h3>{messageContent}</h3>
            <div align="right">
                <Button color="secondary" onClick={()=>closeMessage()} >Entendido</Button>
            </div>
        </div>
    )
    const closeModalHistory=()=>{
        window.location.reload()
    setModalHistoryOpen(!modalHistoryIsOpen);
    }
    const bodyModalHistory=(
        <div className='modal-history'>
            <div className='history-top'>
                <div className='history-title'>
                    Historial Calidad
                </div>
                <div  className='history-title'>
                    <button type='button' onClick={()=> closeModalHistory()} className='button-history'>Close</button>
                </div>
            </div>
            <div className='table-container'>
            <MaterialTable
                            title="Historial Ordenes de trabajo"
                            columns={colums}
                            data={dataHistory}    
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                }}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} de {count}',
                                    labelRowsSelect: 'lineas',
                                    labelRowsPerPage: 'lineas por pagina:',
                                    firstAriaLabel: 'Primera página',
                                    firstTooltip: 'Primera página',
                                    previousAriaLabel: 'Página anterior',
                                    previousTooltip: 'Página anterior',
                                    nextAriaLabel: 'Página siguiente',
                                    nextTooltip: 'Página siguiente',
                                    lastAriaLabel: 'Última página',
                                    lastTooltip: 'Última página',
                                },
                                toolbar: {
                                    exportTitle: 'Exportar',
                                    searchTooltip: 'Buscar',
                                    searchPlaceholder: 'Buscar'
                                }
                                }}
                                
                            />
            </div>
        </div>
        )
    useEffect(()=>{
        if(modalHistoryIsOpen){
            console.log('Getting History')
            axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'getHistoryCalidad'}).then(response=>{
            console.log('Esta es la respuesta')
            console.log(response)
            setDataHistory(response.data)
            })
        }
    },[modalHistoryIsOpen])

    return (
    <div className="page-container">
        <div className='leftbar'>
            <img onClick={()=> window.location.href="https://produccion.moldexmexico.com/home"} src={moldex} className='logo-left'/>
        </div>
        <div className='right-content'>
            <div className="topbar">
                <div className='topbar-items'>
                    <div className='h2-left item-topbar'>QR</div>
                    <div className='item-topbar'>Plataform</div>
                </div>
                <div className='topbar-items items-right'>
                    <div className='item-topbar'>Calidad</div>
                    <div className='item-topbar'>
                        <img className='item-icon' src='https://image.flaticon.com/icons/png/512/1077/1077114.png'/>
                    </div>
                </div>
                
            </div>
            <div className='main-content'>
                <div className='vales-view'>
                    <div className='view-title'>
                        <div className='page-title'>
                            CALIDAD
                        </div>
                        <div onClick={()=>setModalHistoryOpen(true)} className='page-title show-history'>
                            Historial
                        </div>
                    </div>
                    <div className='page-real-content'>
                    {/* Aqui van las otras vistas */}
                        <MaterialTable
                            title="Ordenes de trabajo"
                            columns={colums}
                            data={data}    
                            actions={[
                                {
                                    icon: 'camera',
                                    tooltip: 'Ingresar Imagen',
                                    onClick: (event, rowData) => takePicture(rowData.ot, event)
                                },
                                {
                                    icon: 'check',
                                    tooltip: 'Validar',
                                    onClick: (event, rowData) => validateCorrect(rowData.ot, event)
                                },
                                {
                                    icon: 'cancel',
                                    tooltip: 'Cancelar',
                                    onClick: (event, rowData) => validateCancel(rowData.ot, event)
                                }
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                }}
                            localization={{
                                header:{
                                    actions: "Acciones",
                                },
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} de {count}',
                                    labelRowsSelect: 'lineas',
                                    labelRowsPerPage: 'lineas por pagina:',
                                    firstAriaLabel: 'Primera página',
                                    firstTooltip: 'Primera página',
                                    previousAriaLabel: 'Página anterior',
                                    previousTooltip: 'Página anterior',
                                    nextAriaLabel: 'Página siguiente',
                                    nextTooltip: 'Página siguiente',
                                    lastAriaLabel: 'Última página',
                                    lastTooltip: 'Última página',
                                },
                                toolbar: {
                                    exportTitle: 'Exportar',
                                    searchTooltip: 'Buscar',
                                    searchPlaceholder: 'Buscar'
                                }
                                }}
                                
                            />
                            <Modal
                                open={modalHistoryIsOpen}
                                onClose={closeModalHistory}
                            >
                                {bodyModalHistory}
                            </Modal>
                            <Modal
                                open={modalTakePicture}
                                onClose={closePictureModal}
                            >
                                {bodyTakePicture}
                            </Modal>
                            
                            <Modal
                                open={modalCloseOT}
                                onClose={closeCancelOT}
                            >
                                {bodyCancelOT}
                            </Modal>
                            <Modal 
                                open={modalMessage}
                                onClose={closeMessage}
                                >
                                    {bodyMessage}
                            </Modal>
                            
                    </div>
                </div>
            </div> 
        </div>
    </div>
    );
}

export default Calidad;
