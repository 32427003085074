import React, {useEffect, useState} from "react";
import {Storage} from 'aws-amplify';
import '../Styles/vales.css'
import '../Styles/main.css'

function Home (){
  return (
    <div className="page-container">
    </div>
  );
}

export default Home;
