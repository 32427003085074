import React, {useEffect, useState} from "react";
import Auth from "@aws-amplify/auth";
import {Storage} from 'aws-amplify';
import '../Styles/vales.css'
import '../Styles/main.css'
import moldex from '../Media/SVG/moldex-logo.svg'
import MaterialTable from "material-table";
import { Modal, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";
const colums =[
    {
        title : 'Orden de Trabajo',
        sortable: true,
        field:'ot'
    },
    {
        title : 'Descripcion',
        sortable: true,
        field:'info'
    },
    {
        title : 'Fecha de Entrega',
        sortable: true,
        field:'date'
    },
    {
        title : 'Entrega',
        sortable: true,
        field:'entrega'
    },
    {
        title : 'Solicitante',
        sortable: true,
        field:'solicitante'
    },
    {
        title : 'Orden de Traslado',
        sortable: true,
        field:'otraslado'
    },
    {
        title : 'Cantidad',
        sortable: true,
        field:'cantidad'
    },
    {
        title : 'status',
        sortable: true,
        field:'status'
    },
]
const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        width: '400px',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    iconos:{
        cursor: 'pointer'
    }, 
    inputMaterial:{
        width: '100%'
    },
    image:{
        width: '400px',
        height: '400px'
    }
    }));

function PTview ({ onLogIn }){
    const styles= useStyles();
    const [data, setData] = useState([])
    const [actualOT, setActualOT] = useState(null)
    const [modalImage, setModalImage] = useState('')
    const [dataHistory,setDataHistory] = useState([])
    const [comment, setComment] = useState('')
    const [warning, setWarning] = useState('')
    const [message, setMessage] = useState('default')
    const [messageContent, setMessageContent] = useState('default')
    const [modalMessage, setModalMessage] = useState(false)
    const [modalSeePicture, setModalSeePicture] = useState(false)
    const [modalCancelOT, setModalCancelOT] = useState(false);
    const [actualCancelOT, setActualCancelOT] = useState(null)
    const [modalHistoryIsOpen, setModalHistoryOpen] = useState(false)
    
    useEffect(()=>{
        Auth.signIn('gerardo.ramirez04@grupobimbo.com', 'V3d3t3c!23').then((response)=>{
            if(response.challengeName === 'NEW_PASSWORD_REQUIRED'){
                console.log('Cambiando la contrasena')
            }
        })
    },[])

    useEffect(()=>{
        console.log('Getting data from database')
        axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'getOrdenesPT'}).then(response=>{
            console.log('Esta es la respuesta')
            console.log(response)
            setData(response.data)
        })
    },[])
    
    useEffect(()=>{
        if(actualOT != null){
            axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'getImageCalidad', ot: actualOT}).then(response=>{
                console.log('Esta es la respuesta')
                console.log(response.data)
                if(response.status === 200){
                    Storage.get('OrdenesPT/' + response.data).then(respuesta =>{
                        if(respuesta){
                            setModalImage(respuesta)
                        }
                    })
                }
            }) 
        }
    }, [actualOT])
    
    const validateCorrect =async(ot, event)=>{
        console.log('Aprovando PT')
        axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'aprovePT', ot: ot}).then(response=>{
            console.log(response)
            if(response.data === true){
                alert('Validacion exitosa')
            }else{alert('Algo salio mal, sorry')}  
            window.location.reload();
        }) 
    }
    
    const closeModalSeePicture=()=>{
        setActualOT(null)
        setModalSeePicture(!modalSeePicture);
    }
    
    const seePicture =async(ot, event)=>{
        setActualOT(ot)
        setModalSeePicture(true)
    }
    
    const bodySeePicture=(
        <div className={styles.modal}>
            <h2>Imagen Calidad de OT: {actualOT}</h2>
            <img className={styles.image} src={modalImage}/>
            <div align="right">
                <Button color='primary' onClick={()=> closeModalSeePicture()} >CERRAR</Button>
            </div>
        </div>
    )
    const validateCancel = (ot,event) =>{
        setActualCancelOT(ot)
        setModalCancelOT(true)
    }
    const closeModalCancelOT=()=>{
        setActualCancelOT(null)
        setModalCancelOT(!modalCancelOT);
    }
    const AcceptCloseCancelOT= () =>{
        console.log('Really canceling OT', actualCancelOT)
        console.log('Comentario: ' , comment)
        console.log('Length: ', comment.length)
        if(comment.length < 20){
            setWarning('Ingresa un comentario aceptable')
        }else{
            setWarning('')
            axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'cancelPT', ot: actualCancelOT, comment: comment}).then(response=>{
                console.log(response)
                if(response.data === true){
                    setMessage('Mensaje');
                    setMessageContent('Se elimino la OT con exito')
                    closeMessage()
                }else{
                    setMessage('Mensaje');
                    setMessageContent('Ocurrio un error')
                    closeMessage()
                }
                window.location.reload();
            })
        }
    }
    const bodyCancelOT=(
        <div className={styles.modal}>
            <h2>Orden de Trabajo: {actualCancelOT}</h2>
            <input onChange={(e)=>setComment(e.target.value)} style={{width: '100%'}} ></input>
            <label style={{color: 'red'}}>{warning}</label>
            <h3>¿Estas seguro de cancelar la Orden de Trabajo?</h3>
            <div align="right">
                <Button color="secondary" onClick={()=>closeModalCancelOT()} >Cancelar</Button>
                <Button color='primary' onClick={()=>AcceptCloseCancelOT()} >Aceptar</Button>
            </div>
        </div>
    )

    const closeModalHistory=()=>{
        window.location.reload()
    setModalHistoryOpen(!modalHistoryIsOpen);
    }
    const bodyModalHistory=(
        <div className='modal-history'>
            <div className='history-top-pt'>
                <div className='history-title'>
                    Historial PT
                </div>
                <div  className='history-title'>
                    <button type='button' onClick={()=> closeModalHistory()} className='button-history'>Close</button>
                </div>
            </div>
            <div className='table-container'>
            <MaterialTable
                title="Historial Ordenes de trabajo"
                columns={colums}
                data={dataHistory}    
                options={{
                    actionsColumnIndex: -1,
                    exportButton: true,
                    }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'lineas',
                        labelRowsPerPage: 'lineas por pagina:',
                        firstAriaLabel: 'Primera página',
                        firstTooltip: 'Primera página',
                        previousAriaLabel: 'Página anterior',
                        previousTooltip: 'Página anterior',
                        nextAriaLabel: 'Página siguiente',
                        nextTooltip: 'Página siguiente',
                        lastAriaLabel: 'Última página',
                        lastTooltip: 'Última página',
                    },
                    toolbar: {
                        exportTitle: 'Exportar',
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar'
                    }
                    }}
                    
                />
            </div>
        </div>
        )
    
    useEffect(()=>{
        if(modalHistoryIsOpen){
            console.log('Getting History')
            axios.post('https://yv5lxu5b02.execute-api.us-east-1.amazonaws.com/test/functions',{action: 'getHistoryPT'}).then(response=>{
            console.log('Esta es la respuesta')
            console.log(response)
            setDataHistory(response.data)
            })
        }
    },[modalHistoryIsOpen])

    const closeMessage=()=>{
        setModalMessage(!modalMessage)
    }
    
    const bodyMessage=(
        <div className={styles.modal}>
            <h2>{message}</h2>
            <h3>{messageContent}</h3>
            <div align="right">
                <Button color="secondary" onClick={()=>closeMessage()} >Entendido</Button>
            </div>
        </div>
    )
    return (
        <div className="page-container">
            <div className='leftbar'>
                    <img onClick={()=> window.location.href="https://produccion.moldexmexico.com/home"} src={moldex} className='logo-left'/>
            </div>
            <div className='right-content'>
                <div className="topbar">
                    <div className='topbar-items'>
                        <div className='h2-left item-topbar'>QR</div>
                        <div className='item-topbar'>Plataform</div>
                    </div>
                    <div className='topbar-items items-right'>
                        <div className='item-topbar'>Producto Terminado</div>
                        <div className='item-topbar'>
                            <img className='item-icon' src='https://image.flaticon.com/icons/png/512/1077/1077114.png'/>
                        </div>
                    </div>
                    
                </div>
                <div className='main-content'>
                    <div className='vales-view'>
                        <div className='view-title'>
                            <div className='page-title'>
                                PRODUCTO TERMINADO
                            </div>
                            <div onClick={()=>setModalHistoryOpen(true)} className='page-title show-history'>
                                Historial
                            </div>
                        </div>
                        <div className='page-real-content'>
                        {/* Aqui van las otras vistas */}
                        <MaterialTable
                                title="Ordenes de trabajo"
                                columns={colums}
                                data={data}    
                                actions={[
                                    {
                                        icon: 'image',
                                        tooltip: 'Ver Hoja Calidad',
                                        onClick: (event, rowData) => seePicture(rowData.ot, event)
                                    },
                                    {
                                        icon: 'check',
                                        tooltip: 'Validar',
                                        onClick: (event, rowData) => validateCorrect(rowData.ot, event)
                                    },
                                    {
                                        icon: 'cancel',
                                        tooltip: 'Cancelar',
                                        onClick: (event, rowData) => validateCancel(rowData.ot, event)
                                    }
                                ]}
                                options={{
                                    actionsColumnIndex: -1,
                                    exportButton: true,
                                    }}
                                localization={{
                                    header:{
                                        actions: "Acciones",
                                    },
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'lineas',
                                        labelRowsPerPage: 'lineas por pagina:',
                                        firstAriaLabel: 'Primera página',
                                        firstTooltip: 'Primera página',
                                        previousAriaLabel: 'Página anterior',
                                        previousTooltip: 'Página anterior',
                                        nextAriaLabel: 'Página siguiente',
                                        nextTooltip: 'Página siguiente',
                                        lastAriaLabel: 'Última página',
                                        lastTooltip: 'Última página',
                                    },
                                    toolbar: {
                                        exportTitle: 'Exportar',
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar'
                                    }
                                    }}
                                    
                                />
                                <Modal
                                    open={modalHistoryIsOpen}
                                    onClose={closeModalHistory}
                                >
                                    {bodyModalHistory}
                                </Modal>
                                <Modal
                                    open={modalSeePicture}
                                    onClose={closeModalSeePicture}
                                >
                                    {bodySeePicture}
                                </Modal>
                                
                                <Modal
                                    open={modalCancelOT}
                                    onClose={closeModalCancelOT}
                                >
                                    {bodyCancelOT}
                                </Modal>
                                <Modal 
                                    open={modalMessage}
                                    onClose={closeMessage}
                                    >
                                        {bodyMessage}
                                </Modal>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default PTview;
