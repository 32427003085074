/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5bba41b9-095d-4f92-88bf-63fe9c69e3c8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Ctb3y3PQi",
    "aws_user_pools_web_client_id": "52044vlfdg4i0i2v1rrbb49vhu",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "qrcomplementob110f0e86f74428aa245574592162a4e140440-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
